import axios from "axios";

const isProd = true;

export const getDevices = async () => {
  try {
    const response = await axios.get(
      isProd
        ? `https://evolutiondigital.automationshq.io/backend/getDevices`
        : `http://localhost:8000/backend/getDevices`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error sending request to FastAPI:", error);
    throw error; // Re-throw to handle it in the component if needed
  }
};

export const getSpecialDevices = async () => {
  try {
    const response = await axios.get(
      isProd
        ? `https://evolutiondigital.automationshq.io/backend/getSpecialDevices`
        : `http://localhost:8000/backend/getSpecialDevices`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error sending request to FastAPI:", error);
    throw error; // Re-throw to handle it in the component if needed
  }
};

export const getBusinesses = async () => {
  try {
    const response = await axios.get(
      isProd
        ? `https://evolutiondigital.automationshq.io/backend/getBusinesses`
        : `http://localhost:8000/backend/getBusinesses`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error sending request to FastAPI:", error);
    throw error; // Re-throw to handle it in the component if needed
  }
};

export const getRequesters = async () => {
  try {
    // console.log("About to send the request");
    const response = await axios.get(
      isProd
        ? `https://evolutiondigital.automationshq.io/backend/getRequesters`
        : `http://localhost:8000/backend/getRequesters`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
    // return ["Testing"];
  } catch (error) {
    console.error("Error sending request to FastAPI:", error);
    throw error; // Re-throw to handle it in the component if needed
  }
};

export const postNewSubmission = async (formData) => {
  try {
    const response = await axios.post(
      isProd
        ? `https://evolutiondigital.automationshq.io/backend/newSubmission`
        : `http://localhost:8000/backend/newSubmission`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error sending request to FastAPI:", error);
    throw error; // Re-throw to handle it in the component if needed
  }
};

export const getSiteKey = async () => {
  try {
    const response = await axios.get(
      isProd
        ? `https://evolutiondigital.automationshq.io/backend/siteKey`
        : `http://localhost:8000/backend/siteKey`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.dir(response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending request to FastAPI:", error);
    throw error; // Re-throw to handle it in the component if needed
  }
};
