import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormHelperText,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Box,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getBusinesses,
  getDevices,
  getRequesters,
  getSpecialDevices,
  postNewSubmission,
} from "../../utils/api";
import "./SamplesRequestForm.css";
import { MuiTelInput } from "mui-tel-input";
import { parsePhoneNumberWithError } from "libphonenumber-js";
import { countries } from "./components/Countries.js";

const requestReasons = ["Sales", "Replacement", "Other"];
const shippingOptions = [
  "Overnight Delivery",
  "2 Day Delivery",
  "3 Day Delivery",
  "Ground Delivery",
];

const SamplesRequest = () => {
  const [requesters, setRequesters] = useState([]);
  const [requester, setRequester] = useState("");

  const [reason, setReason] = useState("");

  const [selectedBusinessType, setSelectedBusinessType] = useState("");

  const [selectedAddressType, setSelectedAddressType] = useState("");
  const [businessLocation, setBusinessLocation] = useState(""); // Dispays if it's Business

  const [newBusiness, setNewBusiness] = useState("");

  const [existingBusinesses, setBusinesses] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState("");

  const [devices, setDevices] = useState([]);
  const [specialDevices, setSpecialDevices] = useState([]);
  const [rows, setRows] = useState([{ Model: "", Quantity: "" }]);

  const [selectedShippingMethod, setSelectedShippingMethod] = useState("");
  const [plumeId, setPlumeId] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [notes, setNotes] = useState("");
  const [emailError, setEmailError] = useState("");
  const [notesError, setNotesError] = useState("");

  const [address, setAddress] = useState({
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });

  const [selectedCountry, setSelectedCountry] = useState(null);

  const maxLength = 2000;

  const handleAddRow = () => {
    setRows([...rows, { Model: "", Quantity: "" }]);
  };

  const handleRemoveRow = (index) => {
    setRows(rows.filter((_, i) => i !== index));
  };

  const handleTableChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleShippingChange = (event) => {
    setSelectedShippingMethod(event.target.value);
  };

  const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
  };

  useEffect(() => {
    // Define an async function for the API call
    const fetchData = async () => {
      try {
        const requesters = await getRequesters();
        setRequesters(requesters);

        const businesses = await getBusinesses();
        setBusinesses(businesses);

        const devices = await getDevices();
        setDevices(devices);

        const specialDevices = await getSpecialDevices();
        setSpecialDevices(specialDevices);
      } catch (error) {
        console.dir(error);
      }
    };

    fetchData();
  }, []);

  const handleRequesterChange = (event) => {
    setRequester(event.target.value);
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleBusinessType = (event) => {
    setSelectedBusinessType(event.target.value);
    // Reset values when switching between options
    setNewBusiness("");
    setSelectedBusiness("");
  };

  const handleRadioLocationChange = (event) => {
    setSelectedAddressType(event.target.value);
    // Reset values when switching between options
    setBusinessLocation("");
  };

  const handleTextChange = (event) => {
    setNewBusiness(event.target.value);
  };

  const handleTextLocationChange = (event) => {
    setBusinessLocation(event.target.value);
  };

  const handleBusinessChange = (event) => {
    setSelectedBusiness(event.target.value);
  };

  const handlePhoneChange = (phoneNumber, info) => {
    /*let national = info.nationalNumber;
    let intlPrefixes = ['0011', '00', '011'];
    for(let i = 0; i < intlPrefixes.length; i++) {
      let prefix = intlPrefixes[i];
      if (national.startsWith(prefix)) {

      }
    }
    
    if (national.startsWith('011') && national.length === 3 + 10) {
      phoneNumber = `+${info.countryCallingCode} ${national.slice(3)}`;
    }*/
    setPhone(phoneNumber);
  };

  const handlePhoneBlur = (event) => {
    let value = event.target.value;
    if (!value.startsWith("0")) return;
    try {
      let parsed = parsePhoneNumberWithError(value, "US");
      setPhone(parsed.number);
    } catch (e) {
      return;
    }
    //parsePhoneNumberWithError
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    // Basic email validation using regex
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(newEmail)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  const handleNotesChange = (e) => {
    const newNotes = e.target.value;

    // Check if the new notes exceed the maxLength
    if (newNotes.length > maxLength) {
      setNotesError(`Notes must be ${maxLength} characters or less.`);
    } else {
      setNotesError("");
    }

    setNotes(newNotes);
  };

  const clearSubmissionFields = () => {
    setRequester("");
    setReason("");
    setSelectedBusinessType("");
    setSelectedAddressType("");
    setBusinessLocation(""); // Dispays if it's Business
    setNewBusiness("");
    setSelectedBusiness("");
    setRows([{ Model: "", Quantity: "" }]);
    setSelectedShippingMethod("");
    setPlumeId("");
    setEmail("");
    setPhone("");
    setNotes("");
    setEmailError("");
    setNotesError("");
    setSelectedCountry(null);
    setAddress({
      firstName: "",
      lastName: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (
      (newBusiness === null || newBusiness === "") &&
      (selectedBusiness === null || selectedBusiness === "")
    ) {
      alert("Please denote if this is an existing business or a new one.");
      return;
    }

    if (selectedAddressType === null || selectedAddressType === "") {
      alert(
        "Please denote if this a residental or commerical shipping location"
      );
      return;
    }

    let formData = {
      requester: requester,
      purpose: reason,
      plume_id: plumeId,
      phone: phone.replace(/[^\d]/g, ""), // Remove non-numeric characters,
      email: email,
      devices: rows,
      address_type: selectedAddressType,
      address: address,
      business_name: newBusiness !== "" ? newBusiness : selectedBusiness,
      shipping_method: selectedShippingMethod,
      special_instructions: notes,
      business_location:
        selectedAddressType === "Residential" ? "" : businessLocation,
    };

    const response = await postNewSubmission(formData);
    console.dir(response);
    if (200 <= response.StatusCode || response.StatusCode < 400) {
      alert("Form submitted successfully!");
      clearSubmissionFields();
    } else {
      alert(
        `Failed to submit the request. Please Contact Charles@AutomationsHQ.io for support, Please include the following information: Failure due to ${response.StatusCode} Message:${response.Message}`
      );
    }
  };
  return (
    <form
      onSubmit={handleFormSubmit}
      className='form-item'
    >
      {/* Reason for the request */}
      <FormControl
        fullWidth
        required
        margin='normal'
        component='fieldset'
        className='form-item'
      >
        <InputLabel
          id='select-reason-label'
          className='form-item'
        >
          Select Reason
        </InputLabel>
        <Select
          labelId='select-reason-label'
          id='select-reason'
          value={reason || ""}
          label='Select Reason'
          name='reason'
          onChange={handleReasonChange}
          className='form-item'
        >
          <MenuItem
            value=''
            className='form-item'
          >
            <em>None</em>
          </MenuItem>
          {requestReasons.map((reason) => (
            <MenuItem
              key={reason}
              value={reason}
              className='form-item'
            >
              {reason}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Requester selection */}
      <FormControl
        fullWidth
        required
        margin='normal'
        className='form-item'
      >
        <InputLabel
          id='select-requester-label'
          className='form-item'
        >
          Select Requester
        </InputLabel>
        <Select
          labelId='select-requester-label'
          id='select-requester'
          value={requester || ""}
          label='Select Requester'
          name='requester'
          onChange={handleRequesterChange}
          className='form-item'
        >
          <MenuItem
            value=''
            className='form-item'
          >
            <em>None</em>
          </MenuItem>
          {requesters.map((requester) => (
            <MenuItem
              key={requester}
              value={requester}
              className='form-item'
            >
              {requester}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* New or Existing Business Selection */}
      <Box
        display='flex'
        alignItems='center'
        gap={2}
        className='form-item'
      >
        <Typography
          variant='h6'
          gutterBottom
          className='form-item'
          required
        >
          Is this a new business customer or existing business customer?
        </Typography>
        <FormControl
          component='fieldset'
          className='form-item'
          required
        >
          <RadioGroup
            row
            aria-label='options'
            name='options'
            value={selectedBusinessType}
            onChange={handleBusinessType}
            className='form-item'
          >
            <FormControlLabel
              value='text'
              control={<Radio className='form-item' />}
              label='Enter New Business'
              className='form-item'
            />
            <FormControlLabel
              value='dropdown'
              control={<Radio className='form-item' />}
              label='Select Existing Business'
              className='form-item'
            />
          </RadioGroup>
        </FormControl>
        <Box
          sx={{ width: "200px" }}
          className='form-item'
        >
          {selectedBusinessType === "text" && (
            <TextField
              label='Enter New Business'
              value={newBusiness}
              onChange={handleTextChange}
              variant='outlined'
              fullWidth
              className='form-item'
              required
            />
          )}
          {selectedBusinessType === "dropdown" && (
            <FormControl
              fullWidth
              className='form-item'
            >
              <InputLabel
                id='dropdown-label'
                className='form-item'
              >
                Select Existing Business
              </InputLabel>
              <Select
                labelId='dropdown-label'
                id='dropdown'
                value={selectedBusiness}
                label='Select Option'
                onChange={handleBusinessChange}
                className='form-item'
                required
              >
                {existingBusinesses.map((option, index) => (
                  <MenuItem
                    key={`${option}-${index}`} // Combine option with index for a unique key
                    value={option}
                    className='form-item'
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
      </Box>

      {/* Device Types and Details */}
      <Box className='form-item'>
        <Typography
          variant='h6'
          gutterBottom
          className='form-item'
        >
          Device Type(s) <span style={{ color: "red" }}>*</span>
        </Typography>

        <Box
          display='flex'
          justifyContent='space-between'
        >
          <Typography
            variant='subtitle1'
            className='form-item'
          >
            Model
          </Typography>
          <Typography
            variant='subtitle1'
            className='form-item'
          >
            Quantity
          </Typography>
        </Box>

        {rows.map((row, index) => (
          <Box
            display='flex'
            alignItems='center'
            gap={2}
            key={index}
            marginBottom={2}
          >
            <FormControl
              fullWidth
              className='form-item'
            >
              <InputLabel
                id={`Model-select-${index}`}
                className='form-item'
                required
              >
                Please Select
              </InputLabel>
              <Select
                labelId={`Model-select-${index}`}
                value={row.Model}
                label='Please Select'
                onChange={(e) =>
                  handleTableChange(index, "Model", e.target.value)
                }
                className='form-item'
              >
                {devices.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    className='form-item'
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label='1-100'
              value={row.Quantity}
              onChange={(e) =>
                handleTableChange(index, "Quantity", e.target.value)
              }
              fullWidth
              className='form-item'
              required
            />
            <IconButton
              onClick={() => handleRemoveRow(index)}
              disabled={rows.length === 1}
              className='icon-color'
            >
              <DeleteIcon className='icon-color' />
            </IconButton>
          </Box>
        ))}

        <Button
          variant='contained'
          onClick={handleAddRow}
          style={{ marginTop: 16 }}
          className='form-item'
        >
          + Add Device Type
        </Button>

        {/* Plume ID input field, shown only once and below the add device button */}
        {rows.some((row) => specialDevices.includes(row.Model)) && (
          <Box
            marginTop={2}
            className='form-item'
          >
            <TextField
              label='Plume ID'
              value={plumeId}
              onChange={(e) => setPlumeId(e.target.value)}
              variant='outlined'
              fullWidth
              className='form-item'
              required
            />
          </Box>
        )}
      </Box>

      {/* Shipping Methods */}
      <Box
        className='form-item'
        sx={{ width: 300, padding: 2, borderRadius: 1 }}
      >
        <Typography
          variant='h6'
          gutterBottom
          className='form-item'
        >
          Shipping Methods
        </Typography>
        <FormControl
          fullWidth
          className='form-item'
          required
        >
          <InputLabel
            id='shipping-method-label'
            className='form-item'
          >
            Shipping Methods
          </InputLabel>
          <Select
            labelId='shipping-method-label'
            id='shipping-method'
            value={selectedShippingMethod}
            label='Shipping Method'
            onChange={handleShippingChange}
            className='form-item'
            required
          >
            {shippingOptions.map((option) => (
              <MenuItem
                key={option}
                value={option}
                className='form-item'
              >
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Residential vs. Commercial Address */}
      <Box
        className='form-item'
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          padding: 2,
          gap: 4,
        }}
      >
        <Box
          sx={{ padding: 2 }}
          className='form-item'
        >
          <Typography
            variant='h6'
            gutterBottom
            className='form-item'
          >
            Select Address Type?
          </Typography>
          <Typography
            variant='subtitle1'
            gutterBottom
            sx={{
              textAlign: "justify",
              maxWidth: "40ch",
              overflowWrap: "break-word",
            }}
            className='form-item'
          >
            Used for internal location identification in our database, will not
            appear on shipping label. (e.g. Headend, Warehouse, Corporate,
            Toledo Office, etc.)
          </Typography>
        </Box>
        <FormControl
          component='fieldset'
          className='form-item'
          required
        >
          <RadioGroup
            row
            aria-label='options'
            name='options'
            value={selectedAddressType}
            onChange={handleRadioLocationChange}
            sx={{ margin: "20px" }}
            className='form-item'
          >
            <FormControlLabel
              value='Business'
              control={<Radio className='form-item' />}
              label='Business'
              className='form-item'
            />
            <FormControlLabel
              value='Residential'
              control={<Radio className='form-item' />}
              label='Residential'
              className='form-item'
            />
          </RadioGroup>
          <Box sx={{ width: "200px", marginTop: 2 }}>
            {selectedAddressType === "Business" && (
              <TextField
                label='Enter Location'
                value={businessLocation}
                onChange={handleTextLocationChange}
                variant='outlined'
                fullWidth
                className='form-item'
              />
            )}
          </Box>
        </FormControl>
      </Box>

      {/* Recipient */}
      <Box
        sx={{ padding: 2 }}
        className='form-item'
      >
        <Typography
          variant='h6'
          gutterBottom
          className='form-item'
        >
          Name
        </Typography>
        <Box
          display='flex'
          gap={2}
          className='form-item'
        >
          <FormControl
            fullWidth
            className='form-item'
            margin='normal'
          >
            <TextField
              label='First Name'
              value={address.firstName}
              onChange={(e) =>
                handleAddressChange({ ...address, firstName: e.target.value })
              }
              variant='outlined'
              className='form-item'
              required
            />
            {!address.firstName && (
              <FormHelperText
                className='form-item'
                sx={{ color: "red" }}
              >
                Required.
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            fullWidth
            className='form-item'
            margin='normal'
          >
            <TextField
              label='Last Name'
              value={address.lastName}
              onChange={(e) =>
                handleAddressChange({ ...address, lastName: e.target.value })
              }
              variant='outlined'
              className='form-item'
              required
            />
            {!address.lastName && (
              <FormHelperText
                className='form-item'
                sx={{ color: "red" }}
              >
                Required.
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      </Box>

      {/* Address */}
      <Box
        className='form-item'
        sx={{
          padding: 2,
          borderRadius: 1,
          backgroundColor: "var(--background-color)",
        }}
      >
        <Typography
          variant='h6'
          gutterBottom
          className='form-item'
        >
          Address
        </Typography>
        <FormControl
          fullWidth
          className='form-item'
          margin='normal'
        >
          <TextField
            label='Street Address'
            value={address.address1}
            onChange={(e) =>
              handleAddressChange({ ...address, address1: e.target.value })
            }
            variant='outlined'
            className='form-item'
            required
          />
          {!address.address1 && (
            <FormHelperText
              className='form-item'
              sx={{ color: "red" }}
            >
              Required.
            </FormHelperText>
          )}
        </FormControl>
        <FormControl
          fullWidth
          className='form-item'
          margin='normal'
        >
          <TextField
            label='Address Line 2 (Optional)'
            value={address.address2}
            onChange={(e) =>
              handleAddressChange({ ...address, address2: e.target.value })
            }
            variant='outlined'
            className='form-item'
          />
        </FormControl>
        <Box
          display='flex'
          gap={2}
          className='form-item'
          sx={{ marginTop: 2 }}
        >
          <FormControl
            fullWidth
            className='form-item'
            required
          >
            <InputLabel
              id='country-select-label'
              className='form-item'
            >
              Country
            </InputLabel>
            <Select
              labelId='country-select-label'
              id='country-select'
              value={address.country}
              label='Country'
              onChange={(e) => {
                let newCountry = e.target.value;
                let country = countries.find((c) => c.name === newCountry);
                // reset the state
                handleAddressChange({
                  ...address,
                  country: country.name,
                  state: "",
                });
                setSelectedCountry(country);
              }}
              className='form-item'
              required
            >
              {countries.map((country) => (
                <MenuItem
                  key={country.name}
                  value={country.name}
                  className='form-item'
                >
                  {country.name}
                </MenuItem>
              ))}
            </Select>
            {!address.country && (
              <FormHelperText
                className='form-item'
                sx={{ color: "red" }}
              >
                Required.
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            fullWidth
            className='form-item'
          >
            <TextField
              label='City'
              value={address.city}
              onChange={(e) =>
                handleAddressChange({ ...address, city: e.target.value })
              }
              variant='outlined'
              className='form-item'
              required
            />
            {!address.city && (
              <FormHelperText
                className='form-item'
                sx={{ color: "red" }}
              >
                Required.
              </FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box
          display='flex'
          gap={2}
          className='form-item'
          sx={{ marginTop: 2 }}
        >
          <FormControl
            fullWidth
            className='form-item'
            required
          >
            {selectedCountry && ["US", "CA"].includes(selectedCountry.code) ? (
              <>
                <InputLabel
                  id='state-select-label'
                  className='form-item'
                >
                  {(selectedCountry && selectedCountry.statesLabel) ||
                    "State/Province/Region"}
                </InputLabel>
                <Select
                  labelId='state-select-label'
                  id='state-select'
                  value={address.state}
                  label={
                    (selectedCountry && selectedCountry.statesLabel) ||
                    "State/Province/Region"
                  }
                  disabled={selectedCountry === null}
                  onChange={(e) =>
                    handleAddressChange({ ...address, state: e.target.value })
                  }
                  className='form-item'
                  required
                  inputProps={{ maxLength: 3 }} // Ensures state abbreviation stays within 3 characters
                >
                  {selectedCountry &&
                    selectedCountry.states &&
                    selectedCountry.states.map((state) => (
                      <MenuItem
                        key={state.value}
                        value={state.value}
                        className='form-item'
                      >
                        {state.name} ({state.value})
                      </MenuItem>
                    ))}
                </Select>
              </>
            ) : (
              <>
                <TextField
                  label={
                    (selectedCountry && selectedCountry.statesLabel) ||
                    "State/Province/Region"
                  }
                  value={address.state}
                  disabled={selectedCountry === null}
                  onChange={(e) =>
                    handleAddressChange({ ...address, state: e.target.value })
                  }
                  variant='outlined'
                  className='form-item'
                  required
                />
              </>
            )}
            {!address.state && (
              <FormHelperText
                className='form-item'
                sx={{ color: "red" }}
              >
                Required.
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            fullWidth
            className='form-item'
          >
            <TextField
              label='ZIP Code'
              value={address.zip}
              onChange={(e) =>
                handleAddressChange({ ...address, zip: e.target.value })
              }
              variant='outlined'
              className='form-item'
              required
            />
            {!address.zip && (
              <FormHelperText
                className='form-item'
                sx={{ color: "red" }}
              >
                Required.
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      </Box>

      {/* Phone Number */}
      <Box
        className='form-item'
        sx={{
          padding: 2,
          backgroundColor: "var(--background-color)",
          borderRadius: 1,
        }}
      >
        <Typography
          variant='h6'
          gutterBottom
          className='form-item'
        >
          Phone Number
        </Typography>
        <FormControl
          fullWidth
          className='form-item'
          margin='normal'
          required
          error={!phone} // Display error if the phone number is empty
        >
          <MuiTelInput
            label='Enter Phone Number'
            value={phone}
            onChange={handlePhoneChange}
            onBlur={handlePhoneBlur}
            variant='outlined'
            className='form-item'
            required
            defaultCountry='US'
            forceCallingCode
            preferredCountries={["US", "CA", "MX"]}
            onlyCountries={[
              // North America
              "US", // United States
              "CA", // Canada
              "MX", // Mexico
              // Central America
              "BZ", // Belize
              "CR", // Costa Rica
              "SV", // El Salvador
              "GT", // Guatemala
              "HN", // Honduras
              "NI", // Nicaragua
              "PA", // Panama
              // South America
              "AR", // Argentina
              "BO", // Bolivia
              "BR", // Brazil
              "CL", // Chile
              "CO", // Colombia
              "EC", // Ecuador
              "PY", // Paraguay
              "PE", // Peru
              "UY", // Uruguay
              "VE", // Venezuela
              // Caribbean
              "AG", // Antigua and Barbuda
              "BS", // Bahamas
              "BB", // Barbados
              "CU", // Cuba
              "DM", // Dominica
              "DO", // Dominican Republic
              "GD", // Grenada
              "HT", // Haiti
              "JM", // Jamaica
              "KN", // Saint Kitts and Nevis
              "LC", // Saint Lucia
              "VC", // Saint Vincent and the Grenadines
              "TT", // Trinidad and Tobago
            ]}
          ></MuiTelInput>
          {!phone && (
            <FormHelperText
              className='form-item'
              sx={{ color: "red" }}
            >
              Required.
            </FormHelperText>
          )}
        </FormControl>
      </Box>

      {/* Email */}
      <Box
        className='form-item'
        sx={{
          padding: 2,
          backgroundColor: "var(--background-color)",
          borderRadius: 1,
        }}
      >
        <Typography
          variant='h6'
          gutterBottom
          className='form-item'
        >
          Email Address
        </Typography>
        <FormControl
          fullWidth
          className='form-item'
          margin='normal'
          error={Boolean(emailError)}
        >
          <TextField
            label='Enter Email Address'
            value={email}
            onChange={handleEmailChange}
            variant='outlined'
            className='form-item'
            slotProps={{
              htmlInput: {
                type: "email", // Ensures email input type
              },
            }}
            placeholder='example@domain.com'
            sx={{
              color: "var(--text-color)",
              backgroundColor: "var(--background-color)",
              borderColor: "var(--text-color)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: emailError ? "red" : "#b0b0b0", // Light grey border or red if error
                },
                "&:hover fieldset": {
                  borderColor: emailError ? "red" : "#b0b0b0", // Light grey border or red if error
                },
                "&.Mui-focused fieldset": {
                  borderColor: emailError ? "red" : "#b0b0b0", // Light grey border or red if error
                },
              },
            }}
            required
          />
          {emailError && (
            <FormHelperText
              className='form-item'
              sx={{ color: "red" }}
            >
              {emailError}
            </FormHelperText>
          )}
          {!email && (
            <FormHelperText
              className='form-item'
              sx={{ color: "red" }}
            >
              Required.
            </FormHelperText>
          )}
        </FormControl>
      </Box>

      {/* Special Instructions */}
      <Box
        className='form-item'
        sx={{
          padding: 2,
          backgroundColor: "var(--background-color)",
          borderRadius: 1,
        }}
      >
        <Typography
          variant='h6'
          gutterBottom
          className='form-item'
        >
          Notes
        </Typography>
        <FormControl
          fullWidth
          className='form-item'
          margin='normal'
          error={Boolean(notesError)}
        >
          <TextField
            label='Enter your notes'
            value={notes}
            onChange={handleNotesChange}
            variant='outlined'
            multiline
            minRows={4} // Controls the initial number of rows shown
            className='form-item'
            inputProps={{
              maxLength, // Enforces the maximum length directly in the input
            }}
            placeholder={`Enter up to ${maxLength} characters`}
            sx={{
              color: "var(--text-color)",
              backgroundColor: "var(--background-color)",
              borderColor: "var(--text-color)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: notesError ? "red" : "#b0b0b0", // Light grey border or red if error
                },
                "&:hover fieldset": {
                  borderColor: notesError ? "red" : "#b0b0b0", // Light grey border or red if error
                },
                "&.Mui-focused fieldset": {
                  borderColor: notesError ? "red" : "#b0b0b0", // Light grey border or red if error
                },
              },
            }}
          />
          {notesError && (
            <FormHelperText
              className='form-item'
              sx={{ color: "red" }}
            >
              {notesError}
            </FormHelperText>
          )}
          {!notesError && (
            <FormHelperText
              className='form-item'
              sx={{ textAlign: "right" }}
            >
              {notes.length}/{maxLength} characters
            </FormHelperText>
          )}
        </FormControl>
      </Box>

      {/* Submit Button */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start", // Aligns the button to the left
          marginTop: 2,
        }}
        className='form-item'
      >
        <Button
          type='submit'
          variant='contained'
          className='form-item'
          sx={{
            marginTop: 2,
            padding: "10px 20px",
            fontWeight: "bold",
            fontSize: "1rem",
            background: "linear-gradient(135deg, #007BFF, #1E90FF)",
            color: "var(--text-color)",
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
            transition:
              "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
            "&:hover": {
              background: "linear-gradient(135deg, #1E90FF, #007BFF)",
              boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.3)",
              transform: "scale(1.05)",
            },
          }}
        >
          Submit
        </Button>
      </Box>
    </form>
  );
};
export default SamplesRequest;
