export const countries = [
  {
    name: "United States",
    code: "US",
    statesLabel: "State",
    states: [
      { name: "Alabama", value: "AL" },
      { name: "Alaska", value: "AK" },
      { name: "Arizona", value: "AZ" },
      { name: "Arkansas", value: "AR" },
      { name: "California", value: "CA" },
      { name: "Colorado", value: "CO" },
      { name: "Connecticut", value: "CT" },
      { name: "Delaware", value: "DE" },
      { name: "Florida", value: "FL" },
      { name: "Georgia", value: "GA" },
      { name: "Hawaii", value: "HI" },
      { name: "Idaho", value: "ID" },
      { name: "Illinois", value: "IL" },
      { name: "Indiana", value: "IN" },
      { name: "Iowa", value: "IA" },
      { name: "Kansas", value: "KS" },
      { name: "Kentucky", value: "KY" },
      { name: "Louisiana", value: "LA" },
      { name: "Maine", value: "ME" },
      { name: "Maryland", value: "MD" },
      { name: "Massachusetts", value: "MA" },
      { name: "Michigan", value: "MI" },
      { name: "Minnesota", value: "MN" },
      { name: "Mississippi", value: "MS" },
      { name: "Missouri", value: "MO" },
      { name: "Montana", value: "MT" },
      { name: "Nebraska", value: "NE" },
      { name: "Nevada", value: "NV" },
      { name: "New Hampshire", value: "NH" },
      { name: "New Jersey", value: "NJ" },
      { name: "New Mexico", value: "NM" },
      { name: "New York", value: "NY" },
      { name: "North Carolina", value: "NC" },
      { name: "North Dakota", value: "ND" },
      { name: "Ohio", value: "OH" },
      { name: "Oklahoma", value: "OK" },
      { name: "Oregon", value: "OR" },
      { name: "Pennsylvania", value: "PA" },
      { name: "Rhode Island", value: "RI" },
      { name: "South Carolina", value: "SC" },
      { name: "South Dakota", value: "SD" },
      { name: "Tennessee", value: "TN" },
      { name: "Texas", value: "TX" },
      { name: "Utah", value: "UT" },
      { name: "Vermont", value: "VT" },
      { name: "Virginia", value: "VA" },
      { name: "Washington", value: "WA" },
      { name: "West Virginia", value: "WV" },
      { name: "Wisconsin", value: "WI" },
      { name: "Wyoming", value: "WY" },
      // U.S. Territories
      { name: "American Samoa", value: "AS" },
      { name: "Guam", value: "GU" },
      { name: "Northern Mariana Islands", value: "MP" },
      { name: "Puerto Rico", value: "PR" },
      { name: "U.S. Virgin Islands", value: "VI" },
    ],
  },
  {
    name: "Canada",
    code: "CA",
    statesLabel: "State/Province",
    states: [
      { name: "Alberta", value: "AB" },
      { name: "British Columbia", value: "BC" },
      { name: "Manitoba", value: "MB" },
      { name: "New Brunswick", value: "NB" },
      { name: "Newfoundland and Labrador", value: "NL" },
      { name: "Nova Scotia", value: "NS" },
      { name: "Ontario", value: "ON" },
      { name: "Prince Edward Island", value: "PE" },
      { name: "Quebec", value: "QC" },
      { name: "Saskatchewan", value: "SK" },
      { name: "Northwest Territories", value: "NT" },
      { name: "Nunavut", value: "NU" },
      { name: "Yukon", value: "YT" },
    ],
  },
  // Central America
  { name: "Mexico", code: "MX" },
  { name: "Belize", code: "BZ" },
  { name: "Costa Rica", code: "CR" },
  { name: "El Salvador", code: "SV" },
  { name: "Guatemala", code: "GT" },
  { name: "Honduras", code: "HN" },
  { name: "Nicaragua", code: "NI" },
  { name: "Panama", code: "PA" },
  // South America
  { name: "Argentina", code: "AR" },
  { name: "Bolivia", code: "BO" },
  { name: "Brazil", code: "BR" },
  { name: "Chile", code: "CL" },
  { name: "Colombia", code: "CO" },
  { name: "Ecuador", code: "EC" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Uruguay", code: "UY" },
  { name: "Venezuela", code: "VE" },
  // Caribbean
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Bahamas", code: "BS" },
  { name: "Barbados", code: "BB" },
  { name: "Cuba", code: "CU" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Grenada", code: "GD" },
  { name: "Haiti", code: "HT" },
  { name: "Jamaica", code: "JM" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Trinidad and Tobago", code: "TT" },
];
